import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import Header from "../header/header.component";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import { useAppDispatch } from "state/store";
import { useSelector } from "react-redux";
import { getSendOrderSlice, setSelectedSendOrderValue } from "state/feature/send-order/send-order.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import TextField from "components/TextFieldV2/text-field-v2.component";
import FacilityDropdown from "components/facility-dropdown/facility-dropdown.component";
import SendOrderFooter from "./component/footer/footer.component";
import { SendOrderPresentationProps } from "./common/send-order.props";
import Error from "components/error-message/error-message.component";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import { getNavigatorState } from "state/feature/navigator/navigator.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { useMemo } from "react";
import { getAuth } from "state/feature/auth/auth.slice";
import ManagerTaskApprovalModal from "components/Modal/manager-approval-task-modal/manager-approval-task-modal.container";
import { USER_ROLE } from "shared/constant/role-constant";
import { ACH_ID } from "../check-on-patient/check-on-patient.constant";

const SendOrderPresentation = (props: SendOrderPresentationProps) => {
  const appDispatch = useAppDispatch();
  const {
    handleCancelButton,
    handleOpenDelayButton,
    handleSubmitButton,
    handleSubmitDelayTask,
    isTaskCompletedOrClosed,
    patientDetails,
    handleOpenManagerTaskApprovalButton,
  } = props;
  const {
    isLoading,
    selectedSendOrder,
    providerOptions,
    isDirty,
    sendOrderDetails,
    isLoadingSaveButton,
    facilities,
    isValueChanged,
  } = useSelector(getSendOrderSlice);
  const { currentUserProfile } = useSelector(getNavigatorState);
  const { currentTab } = useSelector(getBrowserState);
  const {
    delayTask: { delayAttempts },
    episode,
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { intakeId, taskId } = currentTab!;

  const { delayTask } = useSelector(getCurrentPageTaskState(taskId));
  const { user } = useSelector(getAuth);
  const isDelayedTaskRequiresApproval =
    delayTask?.delayAttempts?.length > 0 && delayTask?.delayAttempts[0]?.requiresApproval ? true : false;

  const showDelayNote =
    (!isLoading &&
      sendOrderDetails &&
      sendOrderDetails?.taskStatusId !== TaskStatus.CLOSED &&
      sendOrderDetails?.taskStatusId !== TaskStatus.COMPLETED &&
      sendOrderDetails?.taskStatusId !== TaskStatus.NOT_COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayAttempts &&
      delayAttempts?.length > 0 &&
      delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;

  const patientDetailsDisplay = useMemo(() => {
    if (patientDetails) {
      return <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />;
    }
  }, [patientDetails, intakeId]);

  const sendOrderTitle = useMemo(() => {
    if (sendOrderDetails) {
      let newSendOrderTitle = "Send/Inform Care Plan Post Acute";
      if (sendOrderDetails?.tocTaskDetails?.serviceTypeId === ACH_ID) {
        newSendOrderTitle = "Send/Inform Order Acute";
      }
      return (
        <div className="send-order-title-with-label">
          {newSendOrderTitle}
          {selectedSendOrder.sendType && <div className="method-label">{selectedSendOrder.sendType}</div>}
        </div>
      );
    }
    return "";
  }, [sendOrderDetails, selectedSendOrder.sendType]);

  return (
    <>
      {isDelayedTaskRequiresApproval && <ManagerTaskApprovalModal handleSubmit={() => {}} />}

      <DelayModal handleSubmit={handleSubmitDelayTask} />
      <div id="send-order-container">
        <Header className="assessment" patientDetails={patientDetailsDisplay} title={sendOrderTitle} />
        {isLoading && <CommonState type={CommonStateType.LOADING} />}
        {!isLoading && sendOrderDetails === null && (
          <CommonState type={CommonStateType.NO_TASK_FOUND} className="send-order-no-task-found" />
        )}
        {!isLoading && sendOrderDetails && (
          <div className={`send-order-component ${showDelayNote ? "no-answer-delay-note" : ""}`}>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column service-type">
                <label className="field-label">Service Type</label>
                <CustomDropDown
                  placeholder={"-"}
                  dropDownMenuItems={[]}
                  handleValueChanges={(value) => {
                    appDispatch(setSelectedSendOrderValue({ ...selectedSendOrder, serviceType: value }));
                  }}
                  value={selectedSendOrder.serviceType}
                  idFieldName="key"
                  dropDownBoxClass="navigator-dropdown"
                  isDisabled={true}
                  selectionClass="navigator-selection"
                  dropDownContainerClass="toc-tasks-dropdown-container"
                  message="Please select the Service type"
                />
                <div className="error-and-text-length-container"></div>
              </div>
              <div className="send-order-form-column">
                <label className="field-label">LOS/Visit</label>
                <TextField
                  label=""
                  className="send-order-text-field los disabled"
                  inputClassName=""
                  inputContainerClassName=""
                  type="text"
                  value={selectedSendOrder.los}
                  disabled={true}
                  setValue={(label, value) => {}}
                  placeholder={"-"}
                />
                <div className="error-and-text-length-container"></div>
              </div>
            </div>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column provider">
                <label htmlFor="" className="field-label">
                  Provider<span className="red-color">*</span>
                </label>

                {sendOrderDetails?.tocTaskDetails?.serviceTypeId === ACH_ID ? (
                  <CustomDropDown
                    placeholder="Select"
                    dropDownMenuItems={facilities.map((item) => {
                      return { name: item.ProviderName, key: item.ID.toString() };
                    })}
                    handleValueChanges={(value: { key?: string; name: string }) => {
                      appDispatch(
                        setSelectedSendOrderValue({
                          ...selectedSendOrder,
                          providerDetails: { id: value?.key ?? "", name: value?.name },
                        })
                      );
                    }}
                    defaultValue={{
                      name: selectedSendOrder.providerDetails.name,
                      key: selectedSendOrder.providerDetails.id?.toString() ?? "",
                    }}
                    idFieldName="key"
                    dropDownContainerClass="provider-custom-dropdown-container"
                    message="Please select one from dropdown."
                    isError={false}
                    isDisabled={isTaskCompletedOrClosed}
                  />
                ) : (
                  <FacilityDropdown
                    isDisabled={isTaskCompletedOrClosed}
                    dropdownOptions={providerOptions.map((provider) => {
                      return { id: provider.ID, name: provider.ProviderName };
                    })}
                    isError={false}
                    onChange={(id, name) => {
                      appDispatch(setSelectedSendOrderValue({ ...selectedSendOrder, providerDetails: { id, name } }));
                    }}
                    placeholder={isTaskCompletedOrClosed ? "-" : "Select Provider"}
                    selectedOptionId={selectedSendOrder.providerDetails?.id}
                  />
                )}

                <div className="error-and-text-length-container">
                  <div>
                    {!isTaskCompletedOrClosed &&
                      isDirty &&
                      (selectedSendOrder.providerDetails.id === undefined ||
                        selectedSendOrder.providerDetails.id === -1) && <Error message="This field is required" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="send-order-form-field">
              <label className="field-label">Send/Inform Method</label>
              <div className="send-order-form-row">
                <div className="send-order-form-column send-method-type">
                  <CustomDropDown
                    placeholder={"-"}
                    dropDownMenuItems={[]}
                    handleValueChanges={(value) => {
                      appDispatch(setSelectedSendOrderValue({ ...selectedSendOrder, sendMethodType: value }));
                    }}
                    value={selectedSendOrder.sendMethodType}
                    idFieldName="key"
                    dropDownBoxClass="navigator-dropdown"
                    isDisabled={true}
                    selectionClass="navigator-selection"
                    dropDownContainerClass="toc-tasks-dropdown-container"
                  />
                  <div className="error-and-text-length-container"></div>
                </div>
                <div className="send-order-form-column send-method-value">
                  <TextField
                    label=""
                    className="input-field send-order-text-field disabled"
                    inputClassName=""
                    inputContainerClassName=""
                    type="text"
                    disabled={true}
                    value={selectedSendOrder.sendMethodValue}
                    setValue={(label, value) => {
                      appDispatch(setSelectedSendOrderValue({ ...selectedSendOrder, sendMethodValue: value }));
                    }}
                    placeholder={"-"}
                  />
                </div>
                <div className="error-and-text-length-container"></div>
              </div>
            </div>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column preferred-send-date">
                <label htmlFor="" className=" field-label">
                  Preferred Date
                </label>
                <CustomDropDown
                  placeholder={"-"}
                  dropDownMenuItems={[]}
                  handleValueChanges={(value) => {}}
                  value={selectedSendOrder.preferredSendDate}
                  idFieldName="key"
                  dropDownBoxClass="navigator-dropdown"
                  isDisabled={true}
                  selectionClass="navigator-selection"
                  dropDownContainerClass="toc-tasks-dropdown-container"
                />
                <div className="error-and-text-length-container"></div>
              </div>
            </div>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column send-date">
                <label className="field-label">
                  Send/Inform Date
                  <span className="red-color">*</span>
                </label>
                <SingleDatePicker
                  defaultDate={selectedSendOrder.sendDate ?? null}
                  className={`${isTaskCompletedOrClosed ? "disabled-date" : ""}`}
                  isDisabled={isTaskCompletedOrClosed}
                  maxDate={new Date()}
                  handleDateChange={(val: string | null) => {
                    appDispatch(setSelectedSendOrderValue({ ...selectedSendOrder, sendDate: val }));
                  }}
                  popperPlacement="bottom"
                  minDate={null}
                />
                <div className="error-and-text-length-container">
                  <div>
                    {!isTaskCompletedOrClosed && isDirty && !selectedSendOrder.sendDate && (
                      <Error message="This field is required" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column send-instruction">
                <label className="field-label">Send/Inform Instructions</label>
                <textarea
                  className="text-area"
                  id={`textarea`}
                  name={`textarea`}
                  cols={70}
                  rows={10}
                  placeholder={"-"}
                  disabled={true}
                  value={selectedSendOrder.sendInstructions}
                />
                <div className="error-and-text-length-container"></div>
              </div>
            </div>
            <div className="send-order-form-field send-order-form-row">
              <div className="send-order-form-column notes">
                <label className="field-label">Notes</label>
                <textarea
                  className="text-area"
                  id={`textarea`}
                  name={`textarea`}
                  maxLength={5000}
                  cols={70}
                  rows={10}
                  placeholder={isTaskCompletedOrClosed ? "-" : "Write your notes here"}
                  disabled={isTaskCompletedOrClosed}
                  value={selectedSendOrder.notes}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    if (!/^\s/.test(event.currentTarget.value)) {
                      appDispatch(
                        setSelectedSendOrderValue({ ...selectedSendOrder, notes: event.currentTarget.value })
                      );
                    }
                  }}
                />
                <div className="error-and-text-length-container">
                  <div></div>
                  {!isTaskCompletedOrClosed && selectedSendOrder.notes.length > 0 && (
                    <div className="text-length">{`${selectedSendOrder.notes.length}/5000`}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <SendOrderFooter
          handleCancelButton={handleCancelButton}
          isLoadingButton={isLoadingSaveButton}
          handleDelayButton={!isTaskCompletedOrClosed ? handleOpenDelayButton : undefined}
          handleSubmitButton={!isTaskCompletedOrClosed ? handleSubmitButton : undefined}
          isDisabled={isLoading || sendOrderDetails === null || currentUserProfile === null || !isValueChanged}
          isDelayedTaskRequiresApproval={isDelayedTaskRequiresApproval}
          isDisabledDelayedTaskRequiresApproval={!user.roles.includes(USER_ROLE.MANAGER)}
          handleOpenManagerTaskApproval={!isTaskCompletedOrClosed ? handleOpenManagerTaskApprovalButton : undefined}
        />
      </div>
    </>
  );
};
export default SendOrderPresentation;
