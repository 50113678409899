import { CCN_LENGTH, CPT_LENGTH } from "../constant/commonConstants";
import moment from "moment";
import { ZIP_CODE_LENGTH } from "shared/constant/commonConstants";
import { hasSpecialCharacter, validateMBI, validateNumber } from "shared/methods/utilityFunctions";

import { TaskStatus } from "shared/enums/tasks-status.enum";
import { careUtilizationEpisodeStatuses } from "components/Modal/patient-status-modal/patient-status-modal-constants";

export const getInitialsIfPictureNotPresent = (personName: {
  name?: string;
  firstName?: string;
  lastName?: string;
}): string => {
  const { firstName, lastName, name } = personName;
  if (firstName && lastName) {
    const newFirstName = firstName.trim()[0];
    const newLastName = lastName.trim().length > 0 ? lastName.trim()[0] : "";
    const nameInitals = newFirstName + newLastName;
    return nameInitals;
  } else if (name) {
    const arrayOfNames = name
      .trim()
      .split(" ")
      .filter((nameStr) => nameStr !== "");

    // if name is present and surname is also present
    if (name && arrayOfNames.length > 1) {
      const nameIntials = arrayOfNames[0][0] + arrayOfNames[1][0];
      return nameIntials.toUpperCase();
    } else {
      return arrayOfNames[0][0].toUpperCase();
    }
  } else {
    return "N";
  }
};

export const checkTaskIsCompletedClosedOrNotCompleted = (statusId: number | undefined) =>
  statusId ? [TaskStatus.COMPLETED, TaskStatus.CLOSED, TaskStatus.NOT_COMPLETED].includes(statusId) : true;

export const isCptValid = (value: string) => value.length === CPT_LENGTH;

export const isCcnValid = (value: string) => value.length === CCN_LENGTH;

export const isStringEmpty = (str: string) => !str || (typeof str === "string" && str.trim() === "");

export const isProcedureDescriptionValid = (value: string) => 0 < value?.length && value?.length <= 500;

export const isSurgeryDateValid = (admitDate: string, val: string) =>
  !!admitDate && new Date(admitDate) <= new Date(val);
export const isFirstNameValid = (value: string) => !!value && value.length <= 20;

export const isLastNameValid = (value: string) => !!value && value.length <= 50;

export const isMbiValid = (value: string) =>
  !!value && value.length === 11 && validateMBI(value) && !hasSpecialCharacter(value);

export const isEmergencyContactNameValid = (value: string) => value.length <= 40;

export const isEmergencyContactNumberValid = (value: string) => !!value && value.length === 10 && validateNumber(value);

export const isValidPhoneWithDashes = (value: string) => {
  const convertedRegex = value.replace(/\D/g, "");
  return convertedRegex.length === 10;
};

export const isPhoneNumberValid = (value: string) => {
  return !!value && value.length === 12 && isValidPhoneWithDashes(value);
};

export const isEmptyString = (str: string) => typeof str === "string" && str.trim().length === 0;

export const isContainWhiteSpace = (text: string) => /\s/.test(text);

export const isDobValid = (value: Date | string) => {
  let isValid = false;
  if (!value) {
    return isValid;
  }
  const year = new Date(value).getFullYear();
  const age = Math.abs(new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970);
  isValid = year >= 1900 && age >= 18;
  return isValid;
};

export const isZipcodeValid = (value: string) => value.length === ZIP_CODE_LENGTH;

export const isAddressValid = (value: string) => value.length >= 3 && value.length <= 95;

export const isCityValid = (value: string) => value.length >= 3 && value.length <= 85;

export const getLastTendigits = (str: string) => (str ? str.substring(str.length - 10) : str);

export const getDateFormat = (date: string) =>
  date ? `${moment(date).format("YYYY-MM-DD").toString()}T00:00:00` : date;

export const isPatientDeceasedOrDischarged = (optionValue: string) => {
  return (
    optionValue !== careUtilizationEpisodeStatuses.DECEASED || optionValue !== careUtilizationEpisodeStatuses.DISCHARGED
  );
};
