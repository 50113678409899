import Button from "components/Button/button.component";
import "./assessment-footer.styles.scss";
import { useAppDispatch } from "state/store";
import { postAsessmentDataAsync } from "state/feature/assessment/assessment.action";
import { useSelector } from "react-redux";
import { getAssessmentState, setIsValueChanged } from "state/feature/assessment/assessment.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  getTaskManagementState,
  removeTab,
  setLoadTaskList,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import {
  getCommonState,
  setIsOpenDelayModal,
  setIsOpenManagerTaskApprovalModal,
} from "state/feature/common/common.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { ITab } from "state/types/task-management-slice.type";
import { setLockState } from "state/feature/task-management/task-management.action";
import { FORM_SUBMITTED_SUCCESSFULLY } from "shared/constant/commonConstants";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { AssessmentWorkFlowname } from "../assessment.enum";
import { USER_ROLE } from "shared/constant/role-constant";
import { TaskFooterButtonsText } from "shared/enums/task-management-footer.enum";

const AssessmentFooter = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentTab, pages } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;

  const {
    delayTask: { delayAttempts },
  } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const {
    answers,
    formId,
    version,
    taskStatusId,
    isLoading,
    patientId,
    questions,
    isRedirectEnabled,
    id: answerId,
    isAnswersExist,
    isLoadingSubmitButton,
    workflowName,
  } = useSelector(getAssessmentState);
  const { user } = useSelector(getAuth);
  const { redirectParams } = useSelector(getTaskManagementState);
  const { featureFlags } = useSelector(getCommonState);

  const isDelayedTaskRequiresApproval = delayAttempts?.length > 0 && delayAttempts[0]?.requiresApproval ? true : false;

  const handleOpenManagerTaskApprovalButton = () => {
    dispatch(setIsOpenManagerTaskApprovalModal(true));
  };

  const isAssessmentCompleteOrClosed = () =>
    (!isRedirectEnabled && isAnswersExist) ||
    taskStatusId === TaskStatus.CLOSED ||
    taskStatusId === TaskStatus.COMPLETED ||
    taskStatusId === TaskStatus.NOT_COMPLETED;
  const submit = () => {
    dispatch(setIsValueChanged(false));
    if (!isLoadingSubmitButton) {
      dispatch(
        postAsessmentDataAsync({
          patientId: patientId,
          loggedInUserEmail: user.email,
          formId,
          version,
          workflowId: taskId,
          isSaveDraft: false,
          intakeId: parseInt(intakeId),
          id: answerId,
          answers: answers.map(({ selectedValues, questionId }) => {
            return {
              questionId,
              selectionValues: Object.entries(selectedValues).flatMap((item) => {
                if (item[1].length > 1) {
                  const selectedValue: any = [];
                  item[1].forEach((value: any, index: number) => {
                    selectedValue[index] = {
                      id: value.id,
                      value: value.value,
                      nextQuestionId: value.nextQuestionId,
                    };
                  });
                  return selectedValue;
                }
                return { id: item[1][0].id, value: item[1][0].value, nextQuestionId: item[1][0].nextQuestionId };
              }),
            };
          }),
        })
      ).then((response: any) => {
        if (response.payload?.status === 200) {
          dispatch(setLoadTaskList(true));
          if (redirectParams) {
            toast.success(FORM_SUBMITTED_SUCCESSFULLY, {
              toastId: "form-success",
              containerId: "main",
            });
          } else {
            if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
              history.goBack();
            } else {
              dispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
            }
          }
        }
      });
    }
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const onCancel = () => {
    if (isPageDirty(currentTab!)) {
      dispatch(setShowUnsavedChangesModal(true));
      return;
    }
    dispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
    if (featureFlags.taskManagement && featureFlags.taskManagementListView) {
      dispatch(removeTab(currentTab!.taskId));
      return;
    }
  };

  if (!isRedirectEnabled && isAnswersExist) {
    return null;
  }
  return (
    <div className="assessment-footer-button-container">
      <div className="questions-footer-button-section">
        <div className="footer-buttons-div-container">
          <Button
            text={TaskFooterButtonsText.CANCEL}
            className="delay-button"
            disabled={isLoading}
            onClick={onCancel}
          />
          {!isAssessmentCompleteOrClosed() && isRedirectEnabled && !isDelayedTaskRequiresApproval && (
            <Button
              text={TaskFooterButtonsText.DELAY}
              className="delay-button"
              disabled={isLoading}
              onClick={() => {
                dispatch(expandTaskBoard(false));
                dispatch(setIsOpenDelayModal(true));
              }}
            />
          )}
          {!isAssessmentCompleteOrClosed() && isRedirectEnabled && isDelayedTaskRequiresApproval && (
            <Button
              text={TaskFooterButtonsText.MANAGER_APPROVAL}
              className="manager-approval-button"
              onClick={handleOpenManagerTaskApprovalButton}
              disabled={!user.roles.includes(USER_ROLE.MANAGER)}
            />
          )}
          {!isAssessmentCompleteOrClosed() && (
            <Button
              text={TaskFooterButtonsText.SUBMIT}
              className="submit-button"
              onClick={() => submit()}
              disabled={isLoading || isLoadingSubmitButton || questions?.length === 0 || answers.length === 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentFooter;
