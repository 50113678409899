export enum TaskStatus {
  NEW = 1,
  COMPLETED = 2,
  CLOSED = 3,
  IN_PROGRESS = 4,
  PROCESSING = 5,
  NOT_COMPLETED = 9996,
  WAITING_FOR_APPROVAL = 9999,
  REJECT_TASK_APPROVAL = 9998,
}
