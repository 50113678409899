import { useEffect, useState } from "react";
import AdmitTaskPresentation from "./admit-task.component";
import { useAppDispatch } from "state/store";
import { getAdmitTaskData, getAllFacilities, postAdmitTaskData } from "state/feature/admit-task/admit-task.action";
import "./admit-task.styles.scss";
import {
  getDelayAttempts,
  getTaskDelayStatuses,
  setLockState,
} from "state/feature/task-management/task-management.action";
import { FacilityValue } from "./admit-task.props";
import {
  expandTaskBoard,
  getBrowserState,
  getCurrentPageTaskState,
  removeTab,
  setIsCurrentTaskDirty,
  setIsCurrentTaskTouched,
  setShouldPerformPostSubmitAction,
  setShowUnsavedChangesModal,
} from "state/feature/task-management/task-management.slice";
import { setIsOpenDelayModal, setIsOpenManagerTaskApprovalModal } from "state/feature/common/common.slice";
import { useSelector } from "react-redux";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  getAdmitTaskState,
  resetAdmitTaskWhenChangingTab,
  setActualAdmissionDate,
  setActualDischargeDate,
  setIsDirty,
  setNotes,
  setOrderStatus,
  setProvider,
} from "state/feature/admit-task/admit-task.slice";
import moment from "moment";
import { getAuth } from "state/feature/auth/auth.slice";
import { HandleSubmitDelayTask } from "components/Modal/delay-modal/delay-modal.types";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { TaskType } from "shared/enums/admit-task.enum";
import { ITab } from "state/types/task-management-slice.type";
import { checkTaskIsCompletedClosedOrNotCompleted } from "shared/methods/taskSpecificMethods";

const AdmitTask = () => {
  useAxiosAuthenticated();
  const dispatch = useAppDispatch();
  const { selectedAdmitTaskOptions, data, isValueChanged, facilities } = useSelector(getAdmitTaskState);
  const { user } = useSelector(getAuth);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
  const { currentTab, pages } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const { taskPayload } = useSelector(getCurrentPageTaskState(taskId));

  useEffect(() => {
    if (!taskPayload && taskId && intakeId) {
      dispatch(getTaskDelayStatuses(taskId));
      dispatch(
        getDelayAttempts({
          intakeId: parseInt(intakeId),
          taskId: taskId,
        })
      );
      dispatch(getAdmitTaskData({ intakeId, taskId }));
      dispatch(getAllFacilities(intakeId));
    }
  }, [dispatch, taskId, intakeId, taskPayload]);

  const onFacilityChange = (value: FacilityValue) => {
    dispatch(setProvider({ key: value.providerId, value: value.providerName }));
  };

  const handleOrderStatusChange = (value: any) => {
    dispatch(setOrderStatus(value));
  };

  const handleChangeNotes = (value: string) => {
    dispatch(setNotes(value));
  };

  const handleAdmissionDateChange = (date: string | null) => {
    dispatch(setActualAdmissionDate(date));
  };

  const handleDischargeDateChange = (date: string | null) => {
    dispatch(setActualDischargeDate(date));
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const handleCancelButton = () => {
    if (isPageDirty(currentTab!)) {
      dispatch(setShowUnsavedChangesModal(true));
    } else {
      dispatch(setIsDirty(false));
      dispatch(setLockState({ intakeId: currentTab!.intakeId, taskId: currentTab!.taskId, isLocked: false }));
      dispatch(removeTab(currentTab!.taskId));
    }
  };

  useEffect(() => {
    dispatch(setIsCurrentTaskTouched({ taskId: currentTab?.taskId, isTouched: isValueChanged }));
    dispatch(setIsCurrentTaskDirty({ taskId: currentTab?.taskId, isDirty: isValueChanged }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueChanged]);

  const handleOpenDelayButton = () => {
    dispatch(expandTaskBoard(false));
    dispatch(setIsOpenDelayModal(true));
  };

  const validateValues = (): boolean => {
    return (
      selectedAdmitTaskOptions.actualAdmissionDate !== null &&
      !isEmptyString(selectedAdmitTaskOptions.actualAdmissionDate) &&
      selectedAdmitTaskOptions.actualDischargeDate !== null &&
      !isEmptyString(selectedAdmitTaskOptions.actualDischargeDate) &&
      (TaskType.Provider === data?.taskTypeId
        ? !isEmptyString(selectedAdmitTaskOptions.orderStatus.key) && selectedAdmitTaskOptions.orderStatus.key !== null
        : true) &&
      selectedAdmitTaskOptions.provider?.value !== null &&
      !isEmptyString(selectedAdmitTaskOptions.provider?.value)
    );
  };

  const handleSubmitButton = async (isSaveDraft = false) => {
    if (!isSaveDraft) {
      setIsSubmitButtonPressed(true);
    } else {
      setIsSubmitButtonPressed(false);
    }
    if (validateValues() || isSaveDraft) {
      const provider = facilities.find(
        (facility) =>
          !isEmptyString(selectedAdmitTaskOptions.provider.key) &&
          parseInt(selectedAdmitTaskOptions.provider.key) === facility.id
      );
      const response = await dispatch(
        postAdmitTaskData({
          intakeId: parseInt(intakeId),
          taskId,
          actualAdmissionDate: selectedAdmitTaskOptions.actualAdmissionDate
            ? moment(selectedAdmitTaskOptions.actualAdmissionDate).format("MM/DD/YYYY")
            : "",
          actualDischargeDate: selectedAdmitTaskOptions.actualDischargeDate
            ? moment(selectedAdmitTaskOptions.actualDischargeDate).format("MM/DD/YYYY")
            : "",
          orderStatus: TaskType.Provider === data?.taskTypeId ? selectedAdmitTaskOptions.orderStatus?.name : "",
          notes: selectedAdmitTaskOptions.notes,
          isSaveDraft,
          providerId: selectedAdmitTaskOptions.provider?.key,
          provider: provider?.providerName ?? selectedAdmitTaskOptions.provider?.value,
          providerType: provider?.providerTypeName ?? "",
          updateUser: `${user.firstName} ${user.lastName}`,
          updateUserId: user.id,
          updateDate: moment().utc().format(),
          peopleAssigned:
            data?.tocTaskDetails && data?.tocTaskDetails.peopleAssigned ? data?.tocTaskDetails.peopleAssigned : [],
        })
      ).unwrap();
      if (response.status === 200) {
        dispatch(setIsOpenDelayModal(false));
        if (!isSaveDraft) {
          dispatch(setShouldPerformPostSubmitAction({ taskId, performAction: true }));
        }
      }

      return new Promise((resolve, reject) => {
        resolve(response);
      });
    } else {
      if (!isSaveDraft) {
        return Promise.reject(400);
      }
    }
  };

  const handleSubmitDelayTask = (delayTask: HandleSubmitDelayTask) => {
    handleSubmitButton(true)
      ?.then((response: any) => {
        if (response.data.statusCode === 200) {
          delayTask(taskId);
        }
      })
      .catch((error) => {});
  };

  const handleOpenManagerTaskApprovalButton = () => {
    dispatch(setIsOpenManagerTaskApprovalModal(true));
  };

  useEffect(() => {
    return () => {
      dispatch(resetAdmitTaskWhenChangingTab());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AdmitTaskPresentation
      onFacilityChange={onFacilityChange}
      handleOrderStatusChange={handleOrderStatusChange}
      handleAdmissionDateChange={handleAdmissionDateChange}
      handleDischargeDateChange={handleDischargeDateChange}
      handleCancelButton={handleCancelButton}
      handleOpenDelayButton={handleOpenDelayButton}
      handleSubmitButton={(isSaveDraft) => handleSubmitButton(isSaveDraft)?.catch(() => {})}
      handleSubmitDelayTask={handleSubmitDelayTask}
      handleChangeNotes={handleChangeNotes}
      isSubmitButtonPressed={isSubmitButtonPressed}
      isValid={validateValues()}
      isTaskCompletedOrClosed={checkTaskIsCompletedClosedOrNotCompleted(data?.taskStatusId)}
      handleOpenManagerTaskApprovalButton={handleOpenManagerTaskApprovalButton}
    />
  );
};

export default AdmitTask;
