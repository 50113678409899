import Header from "pages/task-management/header/header.component";
import ActiveTabWithTopLine from "components/active-tab-wIth-topline/active-tab-with-topline.component";
import GeneralQuestions from "./tabs/general-assessment/general-assessment.component";
import "./risk-assessment.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getRiskAssessmentState,
  setActivePage,
  setShowPostAcuteTaskCompletionPopUp,
} from "state/feature/risk-assessment/risk-assessment.slice";
import LaceQuestions from "./tabs/lace-assessment/lace-assessment.component";
import ClosingQuestions from "./tabs/closing-assessment/closing-assessment.component";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useAppDispatch } from "state/store";
import { saveRiskAssessmentAnswersAsync } from "state/feature/risk-assessment/risk-assessment.action";
import { Pages, SaveRiskAssessmentPayload } from "./common/risk-assessment.types";
import { getAuth } from "state/feature/auth/auth.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { toast } from "react-toastify";
import AdditionalQuestions from "./tabs/additional-questions/additional-questions.componenet";
import { removeLastDashFromUrl } from "shared/methods/utilityFunctions";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import { HandleSubmitDelayTask } from "components/Modal/delay-modal/delay-modal.types";
import { FormType } from "shared/enums/form-types.enum";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import { getCommonState } from "state/feature/common/common.slice";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import ManagerTaskApprovalModal from "components/Modal/manager-approval-task-modal/manager-approval-task-modal.container";

const RiskAssessmentPresentation = () => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isValueChanged, pages, selectedValues, taskStatusId, collapsibleSections, id } =
    useSelector(getRiskAssessmentState);
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails, isValidIntake, intakeId, taskId, delayTask, episode } = useSelector(
    getCurrentPageTaskState(currentTab!.taskId)
  );
  const { user } = useSelector(getAuth);
  const { featureFlags } = useSelector(getCommonState);
  const setActiveTab = () => {};
  const handleExist = (event: any) => {
    event.returnValue = null;
  };

  useEffect(() => {
    window.removeEventListener("beforeunload", handleExist, true);
    if (isValueChanged) {
      window.addEventListener("beforeunload", handleExist, true);
    }

    return () => {
      window.removeEventListener("beforeunload", handleExist, true);
    };
  }, [isValueChanged]);

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />;
    }
  }, [patientDetails, intakeId]);

  const saveAnswers = useCallback(
    async (isPublish: boolean, saveAndClose?: boolean) => {
      if (isValidIntake && patientDetails) {
        const sectionAnswers = selectedValues.map((selectedValue) => {
          return {
            sectionId: selectedValue.sectionId,
            pageId: selectedValue.pageId,
            order: selectedValue.sectionOrder,
            answers: Object.entries(selectedValue.answers).map(([questionId, selectionValues]) => ({
              questionId,
              order: selectionValues[0].order,
              selectionValues: selectionValues,
              collapsibleSectionId: selectionValues[0].collapsibleSectionId,
            })),
          };
        });

        if (sectionAnswers && sectionAnswers.length > 0) {
          const payload: SaveRiskAssessmentPayload = {
            id,
            patientID: patientDetails?.patientId ? patientDetails?.patientId : 0,
            taskId,
            isLosUpdated: false,
            intakeID: parseInt(intakeId, 10),
            createUser: user.username,
            isSaveDraft: isPublish ? false : !status || status === "draft",
            sectionAnswers,
            collapsibleSections: collapsibleSections.map((x: any) => x.sectionId),
          };

          await appDispatch(saveRiskAssessmentAnswersAsync(payload)).unwrap();
          if (saveAndClose) {
            toast.success("Saved successfully as a Draft.", {
              containerId: "main",
            });
          } else if (isPublish) {
            toast.success("Risk Assessment submitted successfully. Redirecting...", {
              containerId: "main",
            });

            dispatch(setShowPostAcuteTaskCompletionPopUp(true));
            setTimeout(() => history.push(`${removeLastDashFromUrl(location.pathname)}/summary`), 3000);
          }
        }
      }
    },
    [
      appDispatch,
      dispatch,
      history,
      intakeId,
      location.pathname,
      user.username,
      isValidIntake,
      patientDetails,
      taskId,
      collapsibleSections,
      selectedValues,
      id,
    ]
  );

  const handleSubmitDelayTask = async (delaytask: HandleSubmitDelayTask) => {
    if (
      delayTask.intakeDetails &&
      delayTask.intakeDetails.id &&
      delayTask.intakeDetails.tasks &&
      delayTask.intakeDetails.tasks.length > 0
    ) {
      const riskAssessmentTaskId = delayTask.intakeDetails.tasks.find(
        (item) => item.taskTypeId === FormType.RISK_ASSESSMENT
      )?.id;
      if (selectedValues.length > 0) {
        await saveAnswers(false);
      }
      if (!(featureFlags.taskManagement && featureFlags.taskManagementListView)) {
        history.goBack();
      }
      delaytask(riskAssessmentTaskId);
    }
  };

  const onNext = useCallback(
    (nextPage: Pages) => {
      if (taskStatusId !== TaskStatus.CLOSED && taskStatusId !== TaskStatus.NOT_COMPLETED) {
        saveAnswers(false);
      }
      dispatch(setActivePage(nextPage));
    },
    [saveAnswers, dispatch, taskStatusId]
  );

  const showDelayNote =
    (taskStatusId !== TaskStatus.CLOSED &&
      taskStatusId !== TaskStatus.COMPLETED &&
      taskStatusId !== TaskStatus.NOT_COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayTask.delayAttempts &&
      delayTask.delayAttempts?.length > 0 &&
      delayTask.delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;

  return (
    <>
      <div>
        <Header
          className="risk-assessment"
          patientDetails={patientDetailsDisplay()}
          title="Risk Assessment"
          showDelayNote={showDelayNote}
        />
        <ActiveTabWithTopLine onNext={onNext} tabList={Object.values(pages)} changeActiveTabName={setActiveTab} />
        <div className={`assessment-question-container ${showDelayNote ? "no-answer-delay-note-added" : ""}`}>
          {pages.general.isCurrent && <GeneralQuestions />}
          {pages.lace.isCurrent && <LaceQuestions />}
          {pages.additional.isCurrent && <AdditionalQuestions />}
          {pages.closing.isCurrent && <ClosingQuestions />}
        </div>
        <DelayModal handleSubmit={handleSubmitDelayTask} />
        <ManagerTaskApprovalModal handleSubmit={() => {}} />
      </div>
    </>
  );
};

export default RiskAssessmentPresentation;
