import "./patient-task-details.style.scss";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { TASKS_LIMIT, UP_COMING_TASK } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import Icon from "components/Icon/icon.component";
import {
  addNewTab,
  getBrowserState,
  getTaskManagementState,
  memoizeTaskPayloadForCurrentPage,
  setShowTabLimitModal,
} from "state/feature/task-management/task-management.slice";
import { TaskPageName } from "shared/enums/page-name.enum";
import { ITab } from "state/types/task-management-slice.type";
import { getAssessmentState } from "state/feature/assessment/assessment.slice";
import { getNewManualTaskState } from "state/feature/new-manual-task/new-manual-task.slice";
import { getReminderTaskState } from "state/feature/reminder-task/reminder-task.slice";
import { getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { getTOCManagementState } from "state/feature/toc-management/toc-management.slice";
import _ from "lodash";
import TocTasksServiceProvidersDetails from "pages/task-management/components/toc-tasks-service-provider-details/toc-tasks-service-provider-details.component";
import { checkIsTaskIsTOCTaskById, isEmptyString, removeLineBreaksFromText } from "shared/methods/utilityFunctions";
import { getSendOrderSlice } from "state/feature/send-order/send-order.slice";
import { getAdmitTaskState } from "state/feature/admit-task/admit-task.slice";
import { getCheckOnPatientSlice } from "state/feature/check-on-patient/check-on-patient.slice";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { FormType } from "shared/enums/form-types.enum";
import { IntakeStatus } from "shared/enums/intake-tasks-type.enum";

const PatientTaskDetailsPresentation = () => {
  const dispatch = useDispatch();
  const { currentTab, pages, tabs } = useSelector(getBrowserState);
  const { intakeId, taskId } = currentTab!;
  const page = pages.find((el) => el.intakeId === intakeId && el.taskId === taskId)!;
  const { redirectParams } = useSelector(getTaskManagementState);
  const { episodeDetailTabs, isLoadingEpisodeDetailTabs } = useSelector(getEpisodesSlice);
  const isDataAvailable = Array.isArray(episodeDetailTabs.tasks) && episodeDetailTabs.tasks.length > 0;
  const assessmentState = useSelector(getAssessmentState);
  const riskAssessmentState = useSelector(getRiskAssessmentState);
  const tocManagementState = useSelector(getTOCManagementState);
  const reminderTaskState = useSelector(getReminderTaskState);
  const newManualTaskState = useSelector(getNewManualTaskState);
  const sendOrderState = useSelector(getSendOrderSlice);
  const admitTaskState = useSelector(getAdmitTaskState);
  const checkOnPatientState = useSelector(getCheckOnPatientSlice);
  const isIntakeStatusNewProcessing =
    page?.intakeStatusIdIfExistInCosmos &&
    (page.intakeStatusIdIfExistInCosmos === IntakeStatus.NEW ||
      page.intakeStatusIdIfExistInCosmos === IntakeStatus.PROCESSING)
      ? true
      : false;

  const memoizeTask = () => {
    if (!currentTab) {
      return;
    }
    const { taskType } = currentTab;

    let taskPayload = null;
    if (taskType === TaskPageName.RISK_ASSESSMENT) {
      taskPayload = riskAssessmentState;
    } else if (taskType === TaskPageName.TRANSITION_OF_CARE) {
      taskPayload = tocManagementState;
    } else if (taskType === TaskPageName.ASSESSMENT) {
      taskPayload = assessmentState;
    } else if (taskType === TaskPageName.REMINDER_TASK) {
      taskPayload = reminderTaskState;
    } else if (taskType === TaskPageName.NEW_MANUAL_TASK) {
      taskPayload = newManualTaskState;
    } else if (taskType === TaskPageName.SEND_ORDER) {
      taskPayload = sendOrderState;
    } else if (taskType === TaskPageName.ADMIT_TASK) {
      taskPayload = admitTaskState;
    } else if (taskType === TaskPageName.CHECK_ON_PATIENT) {
      taskPayload = checkOnPatientState;
    }

    dispatch(memoizeTaskPayloadForCurrentPage({ taskPayload, currentTab }));
  };

  const handleAddTask = () => {
    if (tabs.length === TASKS_LIMIT) {
      dispatch(setShowTabLimitModal(true));
      return;
    }
    memoizeTask();
    const selectedPage = pages.find((el) => el.intakeId === intakeId && el.taskId === taskId)!;
    const newTab: ITab = {
      taskId: _.uniqueId(),
      intakeId: intakeId!,
      title: `${selectedPage.patientDetails?.patientName} | New Task`,
      isLoading: false,
      taskType: TaskPageName.NEW_MANUAL_TASK,
      taskTypeId: -1,
    };
    dispatch(addNewTab(newTab));
  };

  return (
    <div
      className={`task-container-tab ${
        currentTab?.taskType !== TaskPageName.TRANSITION_OF_CARE ? "footer-present" : "footer-not-present"
      }`}
    >
      {isLoadingEpisodeDetailTabs.tasks ? (
        <div className="loading-container">
          <CommonState type={CommonStateType.LOADING} />
        </div>
      ) : isDataAvailable ? (
        <>
          <div className="header">
            <div className="upcoming-task-title">{UP_COMING_TASK}</div>
            {!redirectParams && isIntakeStatusNewProcessing ? (
              <div className="add-task-cta" onClick={handleAddTask}>
                <Icon icon="plus-add" size={12} className="plus-add-icon" />
                Add Task
              </div>
            ) : null}
          </div>

          <div className="task-list-data">
            {episodeDetailTabs.tasks.map((item) => {
              const {
                taskName,
                startDate,
                dueDate,
                provider,
                providerType,
                isOutdated,
                taskStatus,
                taskTypeId,
                taskStatusId,
                taskTitle,
              } = item;
              return (
                <div className="task-card">
                  <p className="task-name">{taskName}</p>
                  <div className="task-date-container">
                    {startDate && <p> Start: {startDate}</p>}
                    {dueDate && <p> Due: {dueDate}</p>}
                  </div>
                  {(checkIsTaskIsTOCTaskById(taskTypeId) || taskTypeId === FormType.REMINDER) && (
                    <TocTasksServiceProvidersDetails
                      details={
                        taskTypeId === FormType.REMINDER
                          ? `Title: ${taskTitle ?? "-"}`
                          : `${providerType && !isEmptyString(providerType) ? providerType : "-"} | ${
                              provider && !isEmptyString(provider) ? removeLineBreaksFromText(provider) : "-"
                            }`
                      }
                      isObselete={
                        taskTypeId !== FormType.REMINDER &&
                        isOutdated &&
                        taskStatus !== null &&
                        (taskStatusId === TaskStatus.IN_PROGRESS || taskStatusId === TaskStatus.PROCESSING)
                          ? true
                          : false
                      }
                      notShowTooltip={
                        checkIsTaskIsTOCTaskById(taskTypeId) &&
                        (provider === null ||
                          isEmptyString(provider ?? "") ||
                          providerType === null ||
                          isEmptyString(providerType ?? ""))
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <CommonState
          type={CommonStateType.NO_TASK_MANGEMENT_TASK_AVAILABLE}
          handleAddTaskButton={isIntakeStatusNewProcessing ? handleAddTask : undefined}
        />
      )}
    </div>
  );
};

export default PatientTaskDetailsPresentation;
