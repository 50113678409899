import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import DelayModal from "components/Modal/delay-modal/delay-modal.container";
import SingleDatePicker from "components/single-date-picker/single-date-picker.component";
import { useSelector } from "react-redux";
import { getAdmitTaskState } from "state/feature/admit-task/admit-task.slice";
import Header from "../header/header.component";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import moment from "moment";
import TextArea from "pages/episodes/episode-details/components/text-area/text-area.component";
import FacilityDropdown from "../transition-of-care/facility-dropdown";
import { AdmitTaskPresentationProps } from "./admit-task.props";
import AdmitTaskFooter from "./footer/admit-task-footer.component";
import { getDateInMMDDYYYYFormat, isEmptyString } from "shared/methods/utilityFunctions";
import { TaskType } from "shared/enums/admit-task.enum";
import { TaskStatus } from "shared/enums/tasks-status.enum";
import { useCallback } from "react";
import { PatientDetailsHeader } from "components/patient-details-header/patient-details-header.component";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import ManagerTaskApprovalModal from "components/Modal/manager-approval-task-modal/manager-approval-task-modal.container";

const AdmitTaskPresentation = (props: AdmitTaskPresentationProps) => {
  const {
    onFacilityChange,
    handleOrderStatusChange,
    handleAdmissionDateChange,
    handleDischargeDateChange,
    handleCancelButton,
    handleOpenDelayButton,
    handleSubmitButton,
    handleSubmitDelayTask,
    handleChangeNotes,
    isSubmitButtonPressed,
    isValid,
    isTaskCompletedOrClosed,
    handleOpenManagerTaskApprovalButton,
  } = props;
  const { currentTab } = useSelector(getBrowserState);
  const { taskId, intakeId } = currentTab!;
  const { data, isLoading, facilities, isButtonLoading, selectedAdmitTaskOptions, isValueChanged } =
    useSelector(getAdmitTaskState);
  const {
    patientDetails,
    episode,
    delayTask: { delayAttempts },
  } = useSelector(getCurrentPageTaskState(taskId));
  const { user } = useSelector(getAuth);
  const isDelayedTaskRequiresApproval = delayAttempts?.length > 0 && delayAttempts[0]?.requiresApproval ? true : false;

  const showDelayNote =
    (!isLoading &&
      data &&
      data?.taskStatusId !== TaskStatus.CLOSED &&
      data?.taskStatusId !== TaskStatus.COMPLETED &&
      data?.taskStatusId !== TaskStatus.NOT_COMPLETED &&
      episode &&
      episode?.patientAndEpisodeDetails.coachPhone !== null &&
      delayAttempts &&
      delayAttempts?.length > 0 &&
      delayAttempts[0]?.taskDelayStatusId === 1) ??
    false;

  const patientDetailsDisplay = useCallback(() => {
    if (patientDetails) {
      return <PatientDetailsHeader patientDetails={patientDetails} intakeId={intakeId} />;
    }
  }, [patientDetails, intakeId]);

  return (
    <div>
      {isDelayedTaskRequiresApproval && <ManagerTaskApprovalModal handleSubmit={() => {}} />}
      <DelayModal handleSubmit={handleSubmitDelayTask} />
      <div id="admit-task-container">
        <Header
          className="assessment"
          patientDetails={patientDetailsDisplay()}
          title={
            data
              ? `Confirm Patient Admission with ${TaskType.Patient === data?.taskTypeId ? "Patient" : "Provider"}`
              : ""
          }
          showDelayNote={showDelayNote}
        />
        <div className={`task-ui-container ${showDelayNote ? "no-answer-delay-note" : ""}`}>
          {isLoading && <CommonState type={CommonStateType.LOADING} />}
          {!isLoading && data === null && (
            <CommonState type={CommonStateType.NO_TASK_FOUND} className="send-order-no-task-found" />
          )}
          {!isLoading && data && facilities.length > 0 && (
            <>
              <div className="form-section">
                <div className="admit-task-heading">Service Type</div>
                <CustomDropDown
                  dropDownMenuItems={[]}
                  value={{
                    key: data?.tocTaskDetails ? data?.tocTaskDetails.serviceTypeId : "",
                    name:
                      data?.tocTaskDetails && !isEmptyString(data?.tocTaskDetails.serviceTypeName)
                        ? data?.tocTaskDetails.serviceTypeName
                        : "",
                  }}
                  idFieldName=""
                  isDisabled
                  dropDownContainerClass="toc-tasks-dropdown-container"
                  handleValueChanges={() => {}}
                  handleOpenDropdown={() => {}}
                  selectionClass=""
                  dropDownBoxClass=""
                  placeholder="-"
                />
              </div>
              <div className="form-section">
                <div className="admit-task-heading">
                  Provider <span className="astrick">*</span>
                </div>
                <FacilityDropdown
                  facilities={facilities}
                  selectedOptionId={
                    !isEmptyString(selectedAdmitTaskOptions.provider.key)
                      ? parseInt(selectedAdmitTaskOptions.provider?.key)
                      : -1
                  }
                  isError={isSubmitButtonPressed && !selectedAdmitTaskOptions.provider?.value ? true : false}
                  onChange={(providerId: number, providerName: string) => {
                    onFacilityChange({ providerId, providerName });
                  }}
                  isDisabled={isTaskCompletedOrClosed}
                />
                {isSubmitButtonPressed && !selectedAdmitTaskOptions.provider?.value && (
                  <div className="error-color">Please select provider</div>
                )}
              </div>
              {TaskType.Provider === data?.taskTypeId && (
                <div className="form-section">
                  <div className="admit-task-heading">
                    Order Status <span className="astrick">*</span>
                  </div>
                  <CustomDropDown
                    dropDownMenuItems={[
                      {
                        key: "received",
                        name: "Received",
                      },
                      {
                        key: "not received",
                        name: "Not Received",
                      },
                    ]}
                    value={
                      selectedAdmitTaskOptions.orderStatus?.key
                        ? {
                            key: selectedAdmitTaskOptions.orderStatus?.key,
                            name: selectedAdmitTaskOptions.orderStatus?.name,
                          }
                        : { key: "", name: "" }
                    }
                    idFieldName="key"
                    isDisabled={isTaskCompletedOrClosed}
                    handleValueChanges={handleOrderStatusChange}
                    handleOpenDropdown={() => {}}
                    selectionClass=""
                    dropDownBoxClass=""
                    message="Please select order status"
                    placeholder="Select Order Status"
                    dropDownContainerClass="toc-tasks-dropdown-container"
                    isError={isSubmitButtonPressed && !selectedAdmitTaskOptions.orderStatus?.key ? true : false}
                  />
                </div>
              )}
              <div className="form-section">
                <div className="admit-task-heading">
                  Admission Date <span className="astrick">*</span>
                </div>
                <div className="estimated-actual">
                  <div className="date-selector">
                    <div className="admit-task-sub-heading">Estimated</div>
                    <SingleDatePicker
                      defaultDate={
                        data.tocTaskDetails && data.tocTaskDetails.estimatedAdmissionDate
                          ? getDateInMMDDYYYYFormat(
                              moment(data.tocTaskDetails.estimatedAdmissionDate, "YYYY-MM-DDThh:mm:ss")
                            )
                          : null
                      }
                      minDate={null}
                      maxDate={null}
                      isDisabled={true}
                      className={"disabled-date-selector"}
                      isError={false}
                      handleDateChange={(val: any) => {}}
                      popperPlacement="bottom"
                    />
                  </div>
                  <div className="date-selector">
                    <div className="admit-task-sub-heading">
                      Actual <span className="astrick">*</span>
                    </div>
                    <SingleDatePicker
                      defaultDate={selectedAdmitTaskOptions.actualAdmissionDate}
                      minDate={null}
                      maxDate={null}
                      isDisabled={isTaskCompletedOrClosed}
                      className={
                        selectedAdmitTaskOptions.actualAdmissionDate && !isTaskCompletedOrClosed
                          ? ""
                          : "disabled-date-selector"
                      }
                      isError={isSubmitButtonPressed && !selectedAdmitTaskOptions.actualAdmissionDate ? true : false}
                      handleDateChange={(val: any) => {
                        handleAdmissionDateChange(val ? moment(val).toString() : null);
                      }}
                      errorMessage="Please select date"
                      popperPlacement="bottom"
                    />
                  </div>
                </div>
              </div>
              <div className="form-section">
                <div className="admit-task-heading">
                  Discharge Date <span className="astrick">*</span>
                </div>
                <div className="estimated-actual">
                  <div className="date-selector">
                    <div className="admit-task-sub-heading">Estimated</div>
                    <SingleDatePicker
                      defaultDate={
                        data.tocTaskDetails && data.tocTaskDetails.estimatedDischargeDate
                          ? getDateInMMDDYYYYFormat(
                              moment(data.tocTaskDetails.estimatedDischargeDate, "YYYY-MM-DDThh:mm:ss")
                            )
                          : null
                      }
                      minDate={null}
                      maxDate={null}
                      isDisabled={true}
                      className={"disabled-date-selector"}
                      isError={false}
                      handleDateChange={(val: any) => {}}
                      popperPlacement="bottom"
                    />
                  </div>
                  <div className="date-selector">
                    <div className="admit-task-sub-heading">
                      Anticipated <span className="astrick">*</span>
                    </div>
                    <SingleDatePicker
                      defaultDate={selectedAdmitTaskOptions.actualDischargeDate}
                      minDate={null}
                      maxDate={null}
                      isDisabled={isTaskCompletedOrClosed}
                      className={
                        selectedAdmitTaskOptions.actualDischargeDate && !isTaskCompletedOrClosed
                          ? ""
                          : "disabled-date-selector"
                      }
                      isError={isSubmitButtonPressed && !selectedAdmitTaskOptions.actualDischargeDate ? true : false}
                      handleDateChange={(val: any) => {
                        handleDischargeDateChange(val ? moment(val).toString() : null);
                      }}
                      errorMessage="Please select date"
                      popperPlacement="bottom"
                    />
                  </div>
                </div>
              </div>
              <div className="form-section">
                <div className="admit-task-heading">Notes</div>
                <TextArea
                  leftLabel=""
                  rightLabel=""
                  inputText={selectedAdmitTaskOptions.notes}
                  handleChange={handleChangeNotes}
                  rows={5}
                  characterLimit={5000}
                  className="admit-task-text-area"
                  isDisabled={isTaskCompletedOrClosed}
                />
                {!isTaskCompletedOrClosed && selectedAdmitTaskOptions.notes.length > 0 && (
                  <div className="note-length">{selectedAdmitTaskOptions.notes.length} / 5000</div>
                )}
              </div>
            </>
          )}
        </div>
        <AdmitTaskFooter
          handleCancelButton={handleCancelButton}
          isLoadingButton={isButtonLoading}
          handleDelayButton={handleOpenDelayButton}
          handleSubmitButton={() => handleSubmitButton(false)}
          isDisabled={isLoading || data === null || !isValueChanged}
          isTaskCompletedOrClosed={isTaskCompletedOrClosed}
          isDelayedTaskRequiresApproval={isDelayedTaskRequiresApproval}
          isDisabledTaskRequiredApprovalButton={!user.roles.includes(USER_ROLE.MANAGER)}
          handleOpenManagerTaskApprovalButton={handleOpenManagerTaskApprovalButton}
        />
      </div>
    </div>
  );
};

export default AdmitTaskPresentation;
