import React, { useEffect } from "react";
import Icon from "components/Icon/icon.component";
import { ActiveTabWithTopLineProps } from "./active-tab-with-topline.props";
import "./active-tab-with-topline.styles.scss";
import { enablePageValidation, getRiskAssessmentState } from "state/feature/risk-assessment/risk-assessment.slice";
import { useDispatch, useSelector } from "react-redux";
import { IPageConfig } from "state/types/risk-assessment-slice.type";
import { TaskStatus } from "shared/enums/tasks-status.enum";

const ActiveTabWithTopLine = ({ tabList, onNext }: ActiveTabWithTopLineProps) => {
  const dispatch = useDispatch();
  const { pages, taskStatusId } = useSelector(getRiskAssessmentState);

  const getCurrentTab = (): IPageConfig => {
    const currentPage: any = Object.values(pages).find((pageEl: any) => pageEl.isCurrent);
    return currentPage;
  };

  const isTabEnabled = (topLineIsEnabled: boolean): boolean => {
    return topLineIsEnabled && !getCurrentTab().isLoading && getCurrentTab().sectionDetails?.[0]?.questions?.length > 0;
  };

  return (
    <div className="active-tab-with-top-line">
      {tabList.map((singleTab, index) => {
        const topLineIsEnabled =
          (singleTab.isValidationEnabled && singleTab.unAnsweredSections && singleTab.unAnsweredSections.length > 0) ||
          singleTab.tab === 0 ||
          (singleTab.tab > 0 && tabList[singleTab.tab - 1].sections.length > 0);

        return (
          <div key={"key_" + index} className={`single-tab ${singleTab.isCurrent ? "active" : ""}`}>
            <div className="single-tab-section">
              <div className={`top-line ${singleTab.isCurrent ? "top-line-active" : ""}`} />
              <label
                className={`container ${singleTab.isCurrent ? "active" : ""} ${
                  isTabEnabled(topLineIsEnabled) ? "" : "disabled-cursor"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (isTabEnabled(topLineIsEnabled)) {
                    onNext(singleTab.tab);
                    if (taskStatusId !== TaskStatus.CLOSED && taskStatusId !== TaskStatus.NOT_COMPLETED) {
                      dispatch(enablePageValidation(getCurrentTab().tab));
                    }
                  }
                }}
              >
                {!singleTab.isCurrent &&
                singleTab.sections.length > 0 &&
                (!singleTab.unAnsweredSections || singleTab.unAnsweredSections.length === 0) ? (
                  <div className="icon-div">
                    <Icon icon="tick-in-black-background" className="tick-in-black-background" size={18} />
                  </div>
                ) : (
                  <input
                    className={topLineIsEnabled && !singleTab.isLoading ? "" : "disabled-cursor"}
                    type="radio"
                    // defaultChecked={activeTabName === singleTab.name}
                    checked={singleTab.isCurrent}
                    name={singleTab.name}
                    value={singleTab.id}
                    onChange={() => {}}
                  />
                )}
                {singleTab.name}
                {taskStatusId !== TaskStatus.CLOSED &&
                  taskStatusId !== TaskStatus.NOT_COMPLETED &&
                  singleTab.isValidationEnabled &&
                  singleTab.unAnsweredSections &&
                  singleTab.unAnsweredSections.length > 0 && <div className="need-action">(Need Action)</div>}
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ActiveTabWithTopLine;
